import $ from "jquery"

export default {
    state: {
        id: "",
        username: "",
        photo: "",
        token: "",
        is_login: false,
        pulling_info: true,
    },

    getters: {

    },

    mutations: {
        updatedUser(state, user) {
            state.id = user.id,
            state.username = user.username,
            state.photo = user.photo,
            state.is_login = user.is_login
        },

        updatedToken(state, token) {
            state.token = token
        },
        logout(state) {
            state.id = "",
            state.username = "",
            state.photo = "",
            state.token = "",
            state.is_login = false
        },
        updated_pulling_info(state, pulling_info) {
            state.pulling_info = pulling_info;
        }
    },

    actions: {
        login(context, data) {
            $.ajax({
                url: "http://120.26.242.2:3000/user/account/token/",
                type: "post",
                data: {
                    username: data.username,
                    password: data.password,
                },
                success(resp) {
                    if (resp.error_message === "success") {
                        localStorage.setItem("jwt_token", resp.token);
                        context.commit("updatedToken", resp.token);
                        data.success(resp);
                    } else {
                        data.error(resp);
                    }
                },
                error(resp) {
                    data.error(resp);
                }
            });
        },

        getInfo(context, data) {
            $.ajax({
                url: "http://120.26.242.2:3000/user/account/info/",
                type: "get",
                headers: {
                    Authorization: "Bearer " + context.state.token,
                },
                success(resp) {
                    if (resp.error_message === "success") {
                        context.commit("updatedUser", {
                            ...resp,
                            is_login: true,
                        }),
                        data.success();
                    } else data.error();
                },
                error() {
                    // data.error();
                }
            })
        },

        logout(context) {
            localStorage.removeItem("jwt_token");
            context.commit("logout");
        }


    },

    modules: {

    }
}