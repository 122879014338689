<template>
    <ContentField v-if="!$store.state.user.pulling_info">
        <div>
            <p>1. 本游戏是双人联机对战游戏，联机的时候可以亲自出马（即玩家亲自操作）还可以创建java代码让代码自动判断下一步走哪里</p>
            <p>2. 玩家通过按 w s a d ，（上下左右） 来控制蛇下一步的移动，如果下一步碰到障碍物或者对手或者自己身体一部分则游戏结束</p>
            <p>3. 玩家匹配根据天梯分匹配，输了天体分减俩分，赢了加五分。并且每局对局都有回放</p>
            <p>4. 本游戏是回合制游戏，每回合要等俩名玩家都按了下一步，蛇才会移动，如果5秒后没有下一步操作默认认输，前10回合每回合蛇的长度加1之后每三回合蛇的长度加1</p>
            <p>5. 玩家可以通过实现Bot类实现nextStep函数来创建自己的Ai来进行对战以下是一个例子</p>
            <div>
                <pre><code>
                    package com.kob.botrunningsystem.utils;

import java.io.File;
import java.io.FileNotFoundException;
import java.util.ArrayList;
import java.util.List;
import java.util.Scanner;

public class Bot implements java.util.function.Supplier&lt;Integer&gt; {
    static class Cell {
        public int x, y;
        public Cell(int x, int y) {
            this.x = x;
            this.y = y;
        }
    }

    private boolean check_tail_increasing(int step) {
        if (step &lt;= 10) return true;
        return step % 3 == 1;
    }

    public List&lt;Cell&gt; getCells(int sx, int sy, String steps) {
        steps = steps.substring(1, steps.length() - 1);
        List&lt;Cell&gt; res = new ArrayList&lt;&gt;();

        int[] dx = {-1, 0, 1, 0}, dy = {0, 1, 0, -1};
        int x = sx, y = sy;
        int step = 0;
        res.add(new Cell(x, y));
        for (int i = 0; i &lt; steps.length(); i++) {
            int d = steps.charAt(i) - '0';
            x += dx[d];
            y += dy[d];
            res.add(new Cell(x, y));
            if (!check_tail_increasing(++step)) {
                res.remove(0);
            }
        }
        return res;
    }

    public Integer nextMove(String input) {
        String[] strs = input.split("#");
        int[][] g = new int[13][14];
        for (int i = 0, k = 0; i &lt; 13; i++) {
            for (int j = 0; j &lt; 14; j++, k++) {
                if (strs[0].charAt(k) == '1') {
                    g[i][j] = 1;
                }
            }
        }

        int aSx = Integer.parseInt(strs[1]), aSy = Integer.parseInt(strs[2]);
        int bSx = Integer.parseInt(strs[4]), bSy = Integer.parseInt(strs[5]);

        List&lt;Cell&gt; aCells = getCells(aSx, aSy, strs[3]);
        List&lt;Cell&gt; bCells = getCells(bSx, bSy, strs[6]);

        for (Cell c : aCells) g[c.x][c.y] = 1;
        for (Cell c : bCells) g[c.x][c.y] = 1;

        int[] dx = {-1, 0, 1, 0}, dy = {0, 1, 0, -1};
        for (int i = 0; i &lt; 4; i++) {
            int x = aCells.get(aCells.size() - 1).x + dx[i];
            int y = aCells.get(aCells.size() - 1).y + dy[i];
            if (x &gt;= 0 &amp;&amp; x &lt; 13 &amp;&amp; y &gt;= 0 &amp;&amp; y &lt; 14 &amp;&amp; g[x][y] == 0) {
                return i;
            }
        }

        return 0;
    }

    @Override
    public Integer get() {
        File file = new File("input.txt");
        try {
            Scanner sc = new Scanner(file);
            return nextMove(sc.next());
        } catch (FileNotFoundException e) {
            throw new RuntimeException(e);
        }
    }
}
                </code></pre>
            </div>
        </div>
    </ContentField>
</template>

<script>
import ContentField from "@/components/ContentField.vue"

export default {
    components: {
        ContentField,
    },
}
</script>

<style scoped>

</style>
