<template>
    <div class="result-board">
        <div v-if="$store.state.pk.loser === 'all'" class="result-board-info">Draw</div>
        <div v-else-if="$store.state.pk.loser === 'A' && parseInt($store.state.user.id) === $store.state.pk.a_id"
            class="result-board-info">Lose</div>
        <div v-else-if="$store.state.pk.loser === 'B' && parseInt($store.state.user.id) === $store.state.pk.b_id"
            class="result-board-info">Lose</div>
        <div v-else class="result-board-info">Win</div>
        <div class="result-board-btn" @click="result_btn">返回</div>
    </div>
</template>


<script>
import { useStore } from 'vuex';
import router from "../router/index"

export default {

    setup() {
        const store = useStore();
        const result_btn = () => {
            // console.log("result_btn");
            store.commit("updateOpponent", {
                username: "我的对手",
                photo: "https://cdn.acwing.com/media/article/image/2022/08/09/1_1db2488f17-anonymous.png",
            });

            store.commit("updateLoser", "none");
            store.commit("updateStatus", "matching");
            router.push({ name: "pk_index" });

        }

        return {
            result_btn
        }

    }
}

</script>

<style scoped>
.result-board {
    height: 30%;
    width: 30%;
    background-color: rgba(0, 0, 0, 0.5);
    border-radius: 20px;
    position: absolute;
    top: 40%;
    left: 35%;
    right: 35%;
    text-align: center;
    font-size: 24px;
    font-style: italic;
    font-weight: 600;
}

.result-board-info {
    width: 100%;
    height: 50%;
    /* text-align: center; */
    padding-top: 15%;
    color: white;
}

.result-board-btn {
    width: 100%;
    height: 50%;
    /* text-align: center; */
    color: white;
    padding-top: 10%;
}

.result-board-btn:hover {
    cursor: pointer;
    color: burlywood;
    transition: 700ms;
}
</style>