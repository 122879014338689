<template>
    <ContentField>
        <div class="row justify-content-md-center">
            <div class="col-3">
                <form @submit.prevent="register">
                    <div class="mb-3">
                        <label for="username" class="form-label"></label>
                        <input v-model="username" type="text" class="form-control" id="username" placeholder="请输入用户名">
                    </div>
                    <div class="mb-3">
                        <label for="password" class="form-label"></label>
                        <input v-model="password" type="password" class="form-control" id="passwrod" placeholder="请输入密码">
                    </div>
                    <div class="mb-3">
                        <label for="comfirmedPassword" class="form-label"></label>
                        <input v-model="confirmedPassword" type="password" class="form-control" id="comfirmedPasswrod" placeholder="请确认密码">
                    </div>
                    <div class="error-message">{{ error_message }}</div>
                    <button type="submit" class="btn btn-primary butt">注册</button>
                </form>
            </div>
        </div>
    </ContentField>
</template>


<script>
import ContentField from '@/components/ContentField.vue';
import router from '@/router';
import $ from 'jquery'
// import router from '@/router/index';
import { ref } from 'vue'

export default {
    components: {
        ContentField,
    },
    setup() {

        let username = ref('');
        let password = ref('');
        let confirmedPassword = ref('');
        let error_message = ref('');

        const register = () =>{
            
            $.ajax({
                url : "http://120.26.242.2:3000/user/account/register/",
                type: "post",
                data: {
                    username: username.value,
                    password: password.value,
                    confirmedPassword: confirmedPassword.value
                },
                success(resp){
                    if (resp.error_message === "success") {
                        // console.log("success!!!");
                        router.push({name: "user_account_login"})
                    } else {
                        console.log(resp);
                        error_message.value = resp.error_message;
                    }
                }
                
            }) 
        }

        return {
            username,
            password,
            confirmedPassword,
            error_message,
            register
        }
    }
}

</script>


<style scoped>
.butt{
    width: 100%;
}

.error-message{
    color: red;
}
</style>