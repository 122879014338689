<template>
    <div>
  
    </div>
  </template>
  
  <script>
  export default {
    name: "UserStoreJWTView",
    mounted() {
      // 从 URL 参数中获取 token
      const urlParams = new URLSearchParams(window.location.search);
      const token = urlParams.get('token');
      
      if (token) {
        // 将 token 存储到 localStorage
        localStorage.setItem('jwt_token', token);
  
        // 存储成功后跳转到用户登录页面
        window.location.href = 'http://www.iweb3.top/user/account/login/';
      }
    }
  }
  </script>
  