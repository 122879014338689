<template>
    <PlayGround v-if="$store.state.pk.status === 'playing'"></PlayGround>
    <MachGround v-if="$store.state.pk.status === 'matching'"></MachGround>
    <ResultBoard v-if="$store.state.pk.loser !== 'none'"></ResultBoard>
</template>

<script>
import PlayGround from "../../components/PlayGround.vue"
import { onMounted, onUnmounted } from 'vue'
import { useStore } from "vuex"
import MachGround from "../../components/MachGround.vue"
import ResultBoard from "../../components/ResultBoard.vue"

export default {
    components: {
        PlayGround,
        MachGround,
        ResultBoard
    },

    setup() {

        const store = useStore();
        const socketUrl = `ws://120.26.242.2:3000/websocket/${store.state.user.token}/`;


        store.commit("updateLoser", "none");
        store.commit("updateIsRecord", false);

        let socket = null;
        onMounted(() => {
            // console.log(store.state.user.id);
            socket = new WebSocket(socketUrl);
            socket.onopen = () => {
                store.commit("updateSocket", socket);
                store.commit("updateOpponent", {
                    username: "我的对手",
                    photo: "https://cdn.acwing.com/media/article/image/2022/08/09/1_1db2488f17-anonymous.png",
                })
            };

            socket.onmessage = msg => {

                const data = JSON.parse(msg.data);

                if (data.event === "start-matching") {
                    start_matching(data);
                } else if (data.event === "stop-matching") {
                    stop_matching(data);
                } else if (data.event === "move") {
                    move(data);
                } else if (data.event === "result") {
                    result(data);
                }
            }

            socket.onclose = () => {
                // console.log("close");
            }
        })

        const result = (data) => {
            const game = store.state.pk.gameObject;
            if (game !== null) {
                const [snake0, snake1] = game.snakes;
                store.commit("updateLoser", data.loser)

                if (data.loser === "all" || data.loser === "A")
                    snake0.status = "die";
                if (data.loser === "all" || data.loser === "B")
                    snake1.status = "die";

            }


        }

        const move = (data) => {
            const game = store.state.pk.gameObject;
            if (game !== null) {
                const [snake0, snake1] = game.snakes;
                snake0.set_direction(data.a_direction);
                snake1.set_direction(data.b_direction);
            }

        }

        const start_matching = (data) => {
            store.commit("updateOpponent", {
                username: data.opponent_username,
                photo: data.opponent_photo
            }),
                store.commit("updateGame", data.game);
            setTimeout(() => {
                store.commit("updateStatus", "playing");
            }, 1000);

        }

        const stop_matching = (data) => {
            console.log(data);
            console.log("stop-matching");
        }

        onUnmounted(() => {
            socket.close();
            store.commit("updateStatus", "matching");
        })

        return {

        }
    }
}

</script>


<style  scoped></style>