<template>
    <PlayGround></PlayGround>
</template>

<script>
import PlayGround from "../../components/PlayGround.vue"

export default {
    components: {
        PlayGround,
    },

    setup() {


        return {

        }
    }
}

</script>


<style  scoped></style>