<template>
    <ContentField>
        <div style="float: left;">
            <input type="text" class="form-control chaxun" placeholder="请输入所要查询的用户名">
            <button class="btn btn-outline-secondary" type="button" id="button-addon2" style="margin-left: 7px;">查询</button>
        </div>

        <!-- <button class="btn btn-outline-secondary" type="button" id="button-addon2"
            style="float: left; margin-left: 40px">查询所有</button> -->

        <table class="table table-striped table-hover" style="text-align: center;">
            <thead>
                <tr>
                    <th scope="col">A</th>
                    <th scope="col">B</th>
                    <th scope="col">Result</th>
                    <th scope="col">Time</th>
                    <th scope="col">Opertion</th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="record in records" :key="record.record.id">
                    <td>
                        <img class="user-photo" :src="record.a_photo" />
                        &nbsp;
                        <span>{{ record.a_username }}</span>
                    </td>
                    <td>
                        <img class="user-photo" :src="record.b_photo" />
                        &nbsp;
                        <span>{{ record.b_username }}</span>
                    </td>
                    <td class="text">
                        <span>
                            {{ record.result }}
                        </span>
                    </td>
                    <td class="text">
                        <span>
                            {{ record.record.createTime }}
                        </span>

                    </td>
                    <td class="text">
                        <button @click="open_record_content(record.record.id)" type="button"
                            class="btn btn-secondary">查看录像</button>
                    </td>
                </tr>

            </tbody>
        </table>
        <nav aria-label="..." style="float: right;">
            <ul class="pagination">
                <li class="page-item" @click="click_page(-2)">
                    <a class="page-link" href="#">前一页</a>
                </li>
                <li :class="'page-item ' + page.is_active" v-for="page in pages" :key="page.number"
                    @click="click_page(page.number)">
                    <a class="page-link" href="#">{{ page.number }}</a>
                </li>
                <li class="page-item" @click="click_page(-1)">
                    <a class="page-link" href="#">后一页</a>
                </li>
            </ul>
        </nav>

    </ContentField>
</template>

<script>
import ContentField from "../../components/ContentField.vue"
import { useStore } from "vuex";
import { ref } from 'vue'
import $ from "jquery"
import router from "@/router";

export default {
    components: {
        ContentField,
    },
    setup() {
        const store = useStore();
        let records = ref([]);
        let current_page = 1;
        let total_records = 0;
        let pages = ref([]);
        console.log(total_records);

        const click_page = page => {
            if (page === -2) page = current_page - 1;
            else if (page === -1) page = current_page + 1;
            let max_pages = parseInt(Math.ceil(total_records / 10));

            if (page >= 1 && page <= max_pages) {
                pull_page(page);
            }
        }

        const update_pages = () => {
            let max_pages = parseInt(Math.ceil(total_records / 10));
            let new_pages = [];
            for (let i = current_page - 2; i <= current_page + 2; i++) {
                if (i >= 1 && i <= max_pages) {
                    new_pages.push({
                        number: i,
                        is_active: i === current_page ? "active" : "",
                    });
                }
            }

            pages.value = new_pages;
        }

        const pull_page = (page) => {
            current_page = page

            $.ajax({
                url: "http://120.26.242.2:3000/pk/get/record/list/",
                type: "get",
                headers: {
                    Authorization: "Bearer " + store.state.user.token,
                },
                data: {
                    page,
                },
                success(resp) {
                    console.log(resp);
                    records.value = resp.records;
                    total_records = resp.records_count;
                    update_pages();
                    // console.log("records: " + records.value);
                },
                error(resp) {
                    console.log(resp);
                }
            })

        }

        pull_page(current_page);

        const stringTo2D = map => {

            let g = [];
            for (let i = 0, k = 0; i < 13; i++) {
                let line = [];
                for (let j = 0; j < 14; j++, k++) {
                    if (map[k] === '0') line.push(0);
                    else line.push(1);
                }

                g.push(line);
            }

            return g;
        };

        const open_record_content = (recordId) => {
            for (const record of records.value) {
                if (record.record.id === recordId) {
                    store.commit("updateIsRecord", true);
                    console.log(record);
                    store.commit("updateGame", {
                        map: stringTo2D(record.record.map),
                        a_id: record.record.aid,
                        a_sx: record.record.asx,
                        a_sy: record.record.asy,
                        b_id: record.record.bid,
                        b_sx: record.record.bsx,
                        b_sy: record.record.bsy,
                    });
                    store.commit("updateSteps", {
                        a_steps: record.record.asteps,
                        b_steps: record.record.bsteps,
                    }),
                        store.commit("updateRecordLoser", record.record.loser);
                    router.push({
                        name: "record_content",
                        params: {
                            recordId
                        }
                    })
                    break;
                }
            }
        }

        return {
            records,
            open_record_content,
            pages,
            click_page
        }
    }
}

</script>

<style  scoped>
.user-photo {
    width: 15%;
    height: 15%;
    border-radius: 50%;
}

.text>span {
    box-sizing: border-box;
    position: relative;
    top: 13px;
}

.text>button {

    box-sizing: border-box;
    position: relative;
    top: 10px;

}

.chaxun {
    width: 200px;
    float: left;
}
</style>