<template>
    <ContentField>
        <div style="float: left;">
            <input type="text" class="form-control chaxun" placeholder="请输入所要查询的用户名">
            <button class="btn btn-outline-secondary" type="button" id="button-addon2" style="margin-left: 7px;">查询</button>
        </div>

        <!-- <button class="btn btn-outline-secondary" type="button" id="button-addon2"
            style="float: left; margin-left: 40px">查询所有</button> -->

        <table class="table table-striped table-hover" style="text-align: center;">
            <thead>
                <tr>
                    <th scope="col">玩家</th>
                    <th scope="col">天梯分</th>

                </tr>
            </thead>
            <tbody>
                <tr v-for="user in userList" :key="user.id">
                    <th scope="row">
                        <img :src="user.photo" class="user-photo" />
                        &nbsp;
                        <span class="user-username">{{ user.username }}</span>
                    </th>
                    <td>
                        {{ user.rating }}
                    </td>
                </tr>

            </tbody>
        </table>
        <nav aria-label="Page navigation example" style="float: right;">
            <ul class="pagination">
                <li class="page-item" @click="click_page(-2)"><a class="page-link" href="#">前一页</a></li>
                <li class="page-item" v-for="page of pages" :key="page.number" @click="click_page(page.number)">
                    <a :class="'page-link ' + page.is_active" href="#">{{ page.number }}</a>
                </li>
                <li class="page-item" @click="click_page(-1)"><a class="page-link" href="#">后一页</a></li>
            </ul>
        </nav>
    </ContentField>
</template>

<script>
import ContentField from "../../components/ContentField.vue"
import $ from 'jquery'
import { useStore } from "vuex";
import { ref } from "vue";

export default {
    components: {
        ContentField,
    },

    setup() {
        const store = useStore();
        let current_page = 1;
        let userList = ref([]);
        let user_count = 0;
        let pages = ref([]);

        console.log(pages);
        const update_pages = () => {
            let total_page = Math.ceil(user_count / 3);
            let new_pages = [];
            for (let i = current_page - 2; i <= current_page + 2; i++)
                if (i >= 1 && i <= total_page) {
                    new_pages.push({
                        number: i,
                        is_active: i === current_page ? "active" : "",
                    })
                }

            pages.value = new_pages;
        }

        const pull_page = page => {

            current_page = page;

            $.ajax({
                url: "http://120.26.242.2:3000/ranklist/get/list/",
                type: "get",
                data: {
                    page,
                },
                headers: {
                    Authorization: "Bearer " + store.state.user.token,
                },
                success(resp) {
                    // console.log(resp);
                    userList.value = resp.userList;
                    user_count = resp.user_count;
                    update_pages();
                },
                error(resp) {
                    console.log(resp);
                }
            })

        }

        pull_page(1);

        const click_page = page => {
            if (page === -2) page = current_page - 1;
            else if (page === -1) page = current_page + 1;

            // console.log(Math.ceil(user_count / 3));
            if (page >= 1 && page <= Math.ceil(user_count / 3))
                pull_page(page);
        }

        return {
            userList,
            pages,
            click_page
        }
    }
}

</script>

<style  scoped>
.user-photo {
    width: 50px;
    height: 50px;
    border-radius: 50%;
}

.user-username {
    font-weight: 400;
}

.chaxun {
    width: 200px;
    float: left;
}
</style>