<template>
    <div class="container">
        <div class="row">
            <div class="col-3">
                <div class="card" style="margin-top: 20px">
                    <div class="card-body">
                        <img :src="$store.state.user.photo" style="width: 100%">

                    </div>
                </div>
            </div>
            <div class="col-9">
                <div class="card" style="margin-top: 20px;">
                    <div class="card-header">
                        <span style="font-size: 130%;">我的Bot</span>
                        <button @click="clear_message" type="button" class="btn btn-primary btn-sm" style="float: right;"
                            data-bs-toggle="modal" data-bs-target="#add_bot">添加Bot</button>



                        <!-- Modal -->
                        <div class="modal fade" id="add_bot" tabindex="-1" aria-labelledby="exampleModalLabel"
                            aria-hidden="true">
                            <div class="modal-dialog modal-lg">
                                <div class="modal-content">
                                    <div class="modal-header">
                                        <h5 class="modal-title" id="exampleModalLabel">添加Bot</h5>
                                        <button type="button" class="btn-close" data-bs-dismiss="modal"
                                            aria-label="Close"></button>
                                    </div>
                                    <div class="modal-body">

                                        <div class="mb-3">
                                            <label for="title" class="form-label">标题</label>
                                            <input v-model="botadd.title" type="text" class="form-control" id="title"
                                                placeholder="请编写Bot标题">
                                        </div>
                                        <div class="mb-3">
                                            <label for="description" class="form-label">描述</label>
                                            <textarea v-model="botadd.description" class="form-control" id="description"
                                                rows="3" placeholder="请编写Bot描述"></textarea>
                                        </div>
                                        <div class="mb-3">
                                            <label for="code" class="form-label">代码</label>
                                            <VAceEditor v-model:value="botadd.content" @init="editorInit" lang="c_cpp"
                                                theme="textmate" style="height: 300px" />
                                        </div>

                                    </div>
                                    <div class="modal-footer">
                                        <div style="color: red">{{ botadd.error_message }}</div>
                                        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">取消</button>
                                        <button type="button" class="btn btn-primary" @click="add_bot">添加Bot</button>
                                    </div>
                                </div>
                            </div>
                        </div>




                    </div>
                    <div class="card-body">
                        <table class="table table-striped table-hover">
                            <thead>
                                <tr>
                                    <th scope="col">标题</th>
                                    <!-- <th scope="col">Rating</th> -->
                                    <th scope="col">创建时间</th>
                                    <th scope="col">操作</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="bot in bots" :key="bot.id">
                                    <th style="font-weight: 500;">{{ bot.title }}</th>
                                    <!-- <td>{{ bot.rating }}</td> -->
                                    <td>{{ bot.createTime }}</td>
                                    <td>
                                        <button @click="clear_message" type="button" class="btn btn-primary btn-sm"
                                            style="margin-right: 10px;" data-bs-toggle="modal"
                                            :data-bs-target="'#update_bot_modal_' + bot.id">修改</button>
                                        <button type="button" class="btn btn-danger btn-sm"
                                            @click="delete_bot(bot)">删除</button>
                                    </td>



                                    <!-- Modal -->
                                    <div class="modal fade" :id="'update_bot_modal_' + bot.id" tabindex="-1"
                                        aria-labelledby="exampleModalLabel" aria-hidden="true">
                                        <div class="modal-dialog modal-lg">
                                            <div class="modal-content">
                                                <div class="modal-header">
                                                    <h5 class="modal-title" id="exampleModalLabel">修改Bot</h5>
                                                    <button type="button" class="btn-close" data-bs-dismiss="modal"
                                                        aria-label="Close"></button>
                                                </div>
                                                <div class="modal-body">

                                                    <div class="mb-3">
                                                        <label for="title" class="form-label">标题</label>
                                                        <input v-model="bot.title" type="text" class="form-control"
                                                            id="title" placeholder="请编写Bot标题">
                                                    </div>
                                                    <div class="mb-3">
                                                        <label for="description" class="form-label">描述</label>
                                                        <textarea v-model="bot.description" class="form-control"
                                                            id="description" rows="3" placeholder="请编写Bot描述"></textarea>
                                                    </div>
                                                    <div class="mb-3">
                                                        <label for="code" class="form-label">代码</label>
                                                        <VAceEditor v-model:value="bot.content" @init="editorInit"
                                                            lang="c_cpp" theme="textmate" style="height: 300px" />
                                                    </div>

                                                </div>
                                                <div class="modal-footer">
                                                    <div style="color: red">{{ botadd.error_message }}</div>
                                                    <button type="button" class="btn btn-secondary"
                                                        data-bs-dismiss="modal">取消</button>
                                                    <button type="button" class="btn btn-primary"
                                                        @click="update_bot(bot)">保存修改</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>



                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>


<script>
import { useStore } from 'vuex';
import { ref, reactive } from 'vue';
import $ from "jquery"
import { Modal } from 'bootstrap/dist/js/bootstrap'
import { VAceEditor } from 'vue3-ace-editor';
import ace from 'ace-builds';

export default {

    components: {
        VAceEditor,
    },

    setup() {
        ace.config.set(
            "basePath",
            "https://cdn.jsdelivr.net/npm/ace-builds@" + require('ace-builds').version + "/src-noconflict/")

        const store = useStore();
        let bots = ref([]);
        const botadd = reactive({
            title: "",
            description: "",
            content: "",
            error_message: ""
        })

        const clear_message = () => {
            botadd.error_message = "";
        }

        const update_bot = (bot) => {
            botadd.error_message = "";
            $.ajax({
                url: "http://120.26.242.2:3000/user/bot/update/",
                type: "post",
                data: {
                    bot_id: bot.id,
                    title: bot.title,
                    description: bot.description,
                    content: bot.content
                },
                headers: {
                    Authorization: "Bearer " + store.state.user.token,
                },
                success(resp) {
                    console.log("update---")
                    if (resp.error_message === "success") {
                        // console.log(resp);
                        Modal.getInstance('#update_bot_modal_' + bot.id).hide();
                        refresh_bots();
                    } else {
                        botadd.error_message = resp.error_message;
                    }

                },
                error(resp) {
                    botadd.error_message = resp.error_message
                }
            })
        }

        const add_bot = () => {
            botadd.error_message = "";
            $.ajax({
                url: "http://120.26.242.2:3000/user/bot/add/",
                type: "post",
                data: {
                    title: botadd.title,
                    description: botadd.description,
                    content: botadd.content
                },
                headers: {
                    Authorization: "Bearer " + store.state.user.token,
                },
                success(resp) {
                    if (resp.error_message === "success") {
                        refresh_bots();
                        botadd.title = "";
                        botadd.description = "";
                        botadd.content = "";
                        botadd.error_message = "";
                        Modal.getInstance("#add_bot").hide();

                    } else {
                        botadd.error_message = resp.error_message;
                    }

                },
                error(resp) {
                    console.log(resp);
                }
            })
        }


        const refresh_bots = () => {
            $.ajax({
                url: "http://120.26.242.2:3000/user/bot/query/",
                type: "post",
                headers: {
                    Authorization: "Bearer " + store.state.user.token,
                },
                success(resp) {
                    console.log(resp);
                    bots.value = resp;
                },
                error(resp) {
                    console.log(resp);
                }
            })
        }

        refresh_bots();

        const delete_bot = (bot) => {
            $.ajax({
                url: "http://120.26.242.2:3000/user/bot/delete/",
                type: "post",
                data: {
                    bot_id: bot.id,
                },
                headers: {
                    Authorization: "Bearer " + store.state.user.token,
                },
                success() {
                    refresh_bots();
                },
                error(resp) {
                    console.log(resp);
                }
            })
        }

        return {
            store,
            refresh_bots,
            bots,
            delete_bot,
            botadd,
            add_bot,
            update_bot,
            clear_message
        }
    }
}

</script>


<style scoped></style>