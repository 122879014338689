<template>
    <ContentField v-if="!$store.state.user.pulling_info">
        <div class="row justify-content-md-center">
            <dov class="col-3">
                <form @submit.prevent="login">
                    <div class="mb-3">
                        <label for="username" class="form-label"></label>
                        <input v-model="username" type="text" class="form-control" id="username" placeholder="请输入用户名">
                    </div>
                    <div class="mb-3">
                        <label for="password" class="form-label"></label>
                        <input v-model="password" type="password" class="form-control" id="password" placeholder="请输入密码">
                    </div>
                    <div class="error-message">{{ error_message }}</div>
                    
                    <button type="button" @click="login">登入</button>
                    <button type="button" class="qq_login_btn" @click="qq_login"> <img src="../../../assets/images/qq_login.png"></button>
                        
                    
                    
                </form>

            </dov>
        </div>
  
    </ContentField>
</template>


<script>
import ContentField from "../../../components/ContentField.vue"
import { useStore } from 'vuex'
import { ref } from 'vue'
import $ from 'jquery'
import router from "../../../router/index"

export default {
    components: {
        ContentField,
       
    },

    setup() {
        const store = useStore();
        let username = ref('');
        let password = ref('');
        let error_message = ref('');
        const jwt_token = localStorage.getItem("jwt_token");
        if (jwt_token) {
            store.commit("updatedToken", jwt_token);
            store.dispatch("getInfo", {
                success() {
                    router.push({ name: "home" });
                    store.commit("updated_pulling_info", false);
                }
            })
        } else {
            store.commit("updated_pulling_info", false);
            console.log(store.state.user.pulling_info);
        }

        const login = () => {
            error_message.value = "";
            store.dispatch("login", {
                username: username.value,
                password: password.value,
                success() {
                    store.dispatch("getInfo", {
                        success() {

                            router.push({ name: 'home' });
                            // console.log(store.state.user);
                        },
                        error() {
                        }
                    })
                },
                error() {
                    error_message.value = "用户名或密码错误";
                }
            })
        }
        const qq_login = () => {
            $.ajax({
                url: "http://120.26.242.2:3000/qq/login/",
                type: "post",
                data: {},
                xhrFields: {
                    withCredentials: true // 允许发送 cookie
                },
                success(resp) {
                    if (resp.error_message === "success") {
                        window.location.href = resp.url;
                    }
                    // console.log(resp);
                    // console.log("mirey: test---");
                },
                error(resp) {
                    console.log(resp);
                }
            });


        }


        return {
            error_message,
            username,
            password,
            login,
            qq_login
        }
    }
}
</script>


<style scoped>
.error-message {
    color: red;
}

.butt {
    width: 100%;
}

.qq_login_btn {
    margin-left: 20px;
}

</style>